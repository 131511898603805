@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: rgb(51 65 85);
  /* or bg-slate-900 */
}

.btn {
  background: linear-gradient(
    92.23deg,
    #f8b195 20%,
    #f68280 50%,
    #c06c84 100%,
    #355c7d 120%
    /* #ff56f6 20%,
    #b936ee 50%,
    #3bace2 100%,
    #406aff 120% */
  );
  box-shadow: 0px 4.42184px 107.23px rgba(246, 130, 128, 0.60);
  /* box-shadow: 0px 4.42184px 107.23px rgba(255, 86, 246, 0.51); */
}

.btn:hover {
  background: linear-gradient(
    92.23deg,
    #c06c84 20%,
    #f68280 40%,
    #f8b195 70%
    /* #406aff 20%,
    #3bace2 50%,
    #b936ee 100%,
    #ff56f6 120% */
  );
}

.gradient,
.active {
  background: linear-gradient(
    92.23deg,
    /* #355c7d 10%, */
    #c06c84 20%,
    #f68280 40%,
    #f8b195 70%
    /* #355c7d 20%,
    #c06c84 50%,
    #f68280 100%,
    #f8b195 120% */
    /* #406aff 20%,
    #3bace2 50%,
    #b936ee 100%,
    #ff56f6 120% */
  );
}

.active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}

@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    /* color: #369bf3 120%,  */
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#f8b195] to-[#369bf3] font-bold font-primary hover:from-[#369bf3] hover:to-[#f8b195];
    /* @apply bg-gradient-to-r text-transparent bg-clip-text from-[#42A6E3] to-[#FF56F6] font-bold font-primary hover:from-[#FF56F6] hover:to-[#42A6E3]; */
  }
  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center mb-[50px];
  }
}

/* Input box behavior on autofill */
input:-webkit-autofill:focus{
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}
textarea:focus, input:focus{
  outline: none;
}